<template>
  <div class="month-finish-order-gather">
    <van-sticky>
      <van-nav-bar
          left-arrow
          left-text="返回"
          @click-left="goBack"
          :title="moduleTitle"
      />
    </van-sticky>
    <van-cell-group style="margin: 15px 0;">
      <van-cell title="开始月" :value="miniReqParams.startMonth" is-link @click="showSelMonthPopup('s')"/>
      <van-cell title="结束月" :value="miniReqParams.endMonth" is-link @click="showSelMonthPopup('e')"/>
      <div style="text-align: center;">
        <van-button type="primary" @click="sendQuery" size="normal">查询</van-button>
      </div>
    </van-cell-group>
    <van-popup v-model="showStartMonth" position="bottom">
      <van-datetime-picker
          v-model="startMonth"
          type="year-month"
          title="开始年月"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @cancel="monthPopupCancel('s')"
          @confirm="monthPopupSConfirm"
      />
    </van-popup>
    <van-popup v-model="showEndMonth" position="bottom">
      <van-datetime-picker
          v-model="endMonth"
          type="year-month"
          title="结束年月"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @cancel="monthPopupCancel('e')"
          @confirm="monthPopupEConfirm"
      />
    </van-popup>

    <div id="drawDivI" style="width: 100%;height:400px;" class="draw-div"></div>
    <div id="drawDivR" style="width: 100%;height:400px;" class="draw-div"></div>

  </div>
</template>

<script>
import {getRandomColor} from "@/utils/common";

export default {
  name: "Analysis1005",
  data() {
    return {
      moduleTitle: '',
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      showStartMonth: false,
      showEndMonth: false,
      startMonth: new Date(),
      endMonth: new Date(),
      miniReqParams: {
        startMonth: '',
        endMonth: ''
      },
      drawDivs: [],
      existCharts: []
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }
      return val;
    },
    showSelMonthPopup(type) {
      if(type === 's') {
        this.showStartMonth = true;
      } else {
        this.showEndMonth = true;
      }
    },
    monthPopupCancel(type) {
      if(type === 's') {
        this.showStartMonth = false;
      } else {
        this.showEndMonth = false;
      }
    },
    monthPopupSConfirm(value) {
      let month = value.getMonth()+1;
      if(month < 10) month = '0' + month;
      this.miniReqParams.startMonth = value.getFullYear() + '' + month;
      this.showStartMonth = false;
    },
    monthPopupEConfirm(value) {
      let month = value.getMonth()+1;
      if(month < 10) month = '0' + month;
      this.miniReqParams.endMonth = value.getFullYear() + '' + month;
      this.showEndMonth = false;
    },
    sendQuery() {
      //检查月份和限制半年
      let startTime = new Date(this.miniReqParams.startMonth.substring(0,4) + "/" + this.miniReqParams.startMonth.substring(4)).getTime();
      let endTime = new Date(this.miniReqParams.endMonth.substring(0,4) + "/" + this.miniReqParams.endMonth.substring(4)).getTime();
      if(endTime < startTime) {
        this.$notify({message: '结束月份不能少于开始月份', type: 'danger'});
        return;
      }
      if((endTime - startTime) > 15046400000) {
        this.$notify({message: '限制月份区间在6个月内', type: 'danger'});
        return;
      }

      this.$toast.loading({ message: '请求中', overlay: true, duration:0 });
      // //发送查询请求
      this.postRequest('/wechat-analysis/month-finish-order-gather', this.miniReqParams).then(resp => {
        console.log(resp);
        if(resp) {
          if(this.existCharts.length > 0) {
            this.existCharts.forEach(charts => {
              charts.dispose();
            })
            this.existCharts = [];
          }
          if(resp.data) {
            this.$nextTick(() => {
              this.drawCharts(resp.data);
              this.$toast.clear();
            })
          } else {
            this.$toast("查询无数据，请换条件查询！")
          }
        }
      })
    },
    drawCharts(data) {
      //数据组装
      let rgbs = ['rgb(241, 194, 1)','rgb(241, 194, 174)','rgb(130, 200, 231)','rgb(99, 152, 123)','rgb(251, 118, 123)'];
      let dataMap = data.data;
      Object.keys(dataMap).forEach(dataKey => {
        let dataList = dataMap[dataKey];
        let params = {};
        //标题
        params.title = dataKey === 'az' ? '安装' : '维修';
        //名称
        params.legend = data.legend;
        //展示的内容
        params.series = [];
        params.legend.forEach((legendData, legendIndex) => {
          let obj = {};
          obj.name = legendData;
          obj.data = dataList[legendIndex];
          obj.type = 'scatter';
          obj.symbolSize = function (data) {
            return Math.sqrt(data[2]*10000) / 5e2;
          };
          obj.emphasis = {
            focus: 'series',
            label: {
              show: true,
              formatter: function (param) {
                return param.data[3];
              },
              position: 'top'
            }
          }
          obj.itemStyle = {
            shadowBlur: 10,
            shadowColor: 'rgba(120, 36, 50, 0.5)',
            shadowOffsetY: 5,
            color: new this.$echarts.graphic.RadialGradient(0.4, 0.3, 1, [{
              offset: 0,
              color: getRandomColor()
            }, {
              offset: 1,
              color: getRandomColor()
            }])
          }

          params.series.push(obj);
        })
        if(dataKey == 'az') {
          this.myEcharts('drawDivI', params);
        } else {
          this.myEcharts('drawDivR', params);
        }
      })

    },
    myEcharts(id, params){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById(id));

      // 指定图表的配置项和数据
      let option = {
        backgroundColor: new this.$echarts.graphic.RadialGradient(0.3, 0.3, 0.8, [{
          offset: 0,
          color: '#f7f8fa'
        }, {
          offset: 1,
          color: '#cdd0d5'
        }]),
        title: {
          text: params.title,
          left: '5%',
          subtext: '竖轴代表人数，横轴代表工单数'
        },
        legend: {
          right: '10%',
          top: '1%',
          data: params.legend
        },
        grid: {
          left: '11%',
          top: '15%'
        },
        xAxis: {
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
          axisLabel: {
            formatter: function (value, index) {
              //X轴的数值显示格式化主要调整部分
              if (value >= 10000 && value < 10000000) {
                value = value / 10000 + "万";
              }
              return value;
            }
          },
        },
        yAxis: {
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
          scale: true,
          axisLabel: {
            formatter: function (value, index) {
              //Y轴的数值显示格式化主要调整部分
              if (value >= 10000 && value < 10000000) {
                value = value / 10000 + "万";
              }
              return value;
            }
          },
        },
        series: params.series
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      this.existCharts.push(myChart);
    }
  },
  mounted() {
    this.moduleTitle = window.sessionStorage.getItem("moduleTitle");
  }
}
</script>

<style scoped>
.month-finish-order-gather {
  padding-bottom: 15px;
  width: 100%;
}
.draw-div {
  margin-top: 15px;
  background-color: white;
}
.van-cell-group {

}
</style>
